/**
 * Middleware function that redirects to the admin URL if the current path is '/admin'.
 * @param {import('vue-router').RouteLocationNormalized} to - The target route object.
 * @param {import('vue-router').RouteLocationNormalized} from - The current route object.
 * @returns {void}
 */
export default defineNuxtRouteMiddleware((to) => {
  if (to.path === '/admin') {
    const config = useRuntimeConfig()
    return navigateTo(config.public.adminUrl, { external: true})
  }
  })