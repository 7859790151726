<script setup lang="ts">

</script>

<template>
  <footer class="bg-gray-900 text-white py-8 sm:py-12">
    <div class="max-w-screen-xl mx-auto text-center">
      <p class="text-sm">
        Copyright © {{ new Date().getFullYear() }}. All rights reserved.
      </p>
    </div>
  </footer>
</template>

<style scoped>
@media (min-width: 640px) {
  footer {
    padding: 3rem 0;
  }
}
</style>
