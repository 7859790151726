import { default as _91slug_93hBM6AVXD2KMeta } from "/codebuild/output/src1695541226/src/synergos-frontend/pages/blog/[slug].vue?macro=true";
import { default as indexAsigVLeJrmMeta } from "/codebuild/output/src1695541226/src/synergos-frontend/pages/blog/index.vue?macro=true";
import { default as blogRNjWu0iXXzMeta } from "/codebuild/output/src1695541226/src/synergos-frontend/pages/blog.vue?macro=true";
import { default as checkoutSuccesslAzIX1XVIFMeta } from "/codebuild/output/src1695541226/src/synergos-frontend/pages/checkoutSuccess.vue?macro=true";
import { default as dashboarde1KJwxYpE1Meta } from "/codebuild/output/src1695541226/src/synergos-frontend/pages/dashboard.vue?macro=true";
import { default as _91_46_46_46slug_93DBmwknK4LfMeta } from "/codebuild/output/src1695541226/src/synergos-frontend/pages/docs/[...slug].vue?macro=true";
import { default as _91sessionId_93wpOPEClF8XMeta } from "/codebuild/output/src1695541226/src/synergos-frontend/pages/experience/[sessionId].vue?macro=true";
import { default as endUbrNMA156RMeta } from "/codebuild/output/src1695541226/src/synergos-frontend/pages/experience/end.vue?macro=true";
import { default as _91_46_46_46slug_93iT69KVfpZkMeta } from "/codebuild/output/src1695541226/src/synergos-frontend/pages/experience/live/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_931gJaZeq03tMeta } from "/codebuild/output/src1695541226/src/synergos-frontend/pages/experience/wait/[...slug].vue?macro=true";
import { default as indexwE2fHiBAPzMeta } from "/codebuild/output/src1695541226/src/synergos-frontend/pages/index.vue?macro=true";
import { default as loginkoC0JThGavMeta } from "/codebuild/output/src1695541226/src/synergos-frontend/pages/login.vue?macro=true";
import { default as pricingnNpuYZqfXEMeta } from "/codebuild/output/src1695541226/src/synergos-frontend/pages/pricing.vue?macro=true";
import { default as signup4hiaJJgWreMeta } from "/codebuild/output/src1695541226/src/synergos-frontend/pages/signup.vue?macro=true";
import { default as systemCheckrHGiwnufvvMeta } from "/codebuild/output/src1695541226/src/synergos-frontend/pages/systemCheck.vue?macro=true";
import { default as component_45stubWi5ykHMcW8Meta } from "/codebuild/output/src1695541226/src/synergos-frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubWi5ykHMcW8 } from "/codebuild/output/src1695541226/src/synergos-frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: blogRNjWu0iXXzMeta?.name,
    path: "/blog",
    component: () => import("/codebuild/output/src1695541226/src/synergos-frontend/pages/blog.vue").then(m => m.default || m),
    children: [
  {
    name: "blog-slug",
    path: ":slug()",
    component: () => import("/codebuild/output/src1695541226/src/synergos-frontend/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "",
    component: () => import("/codebuild/output/src1695541226/src/synergos-frontend/pages/blog/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "checkoutSuccess",
    path: "/checkoutSuccess",
    meta: checkoutSuccesslAzIX1XVIFMeta || {},
    component: () => import("/codebuild/output/src1695541226/src/synergos-frontend/pages/checkoutSuccess.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboarde1KJwxYpE1Meta || {},
    component: () => import("/codebuild/output/src1695541226/src/synergos-frontend/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "docs-slug",
    path: "/docs/:slug(.*)*",
    component: () => import("/codebuild/output/src1695541226/src/synergos-frontend/pages/docs/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "experience-sessionId",
    path: "/experience/:sessionId()",
    meta: _91sessionId_93wpOPEClF8XMeta || {},
    component: () => import("/codebuild/output/src1695541226/src/synergos-frontend/pages/experience/[sessionId].vue").then(m => m.default || m)
  },
  {
    name: "experience-end",
    path: "/experience/end",
    meta: endUbrNMA156RMeta || {},
    component: () => import("/codebuild/output/src1695541226/src/synergos-frontend/pages/experience/end.vue").then(m => m.default || m)
  },
  {
    name: "experience-live-slug",
    path: "/experience/live/:slug(.*)*",
    meta: _91_46_46_46slug_93iT69KVfpZkMeta || {},
    component: () => import("/codebuild/output/src1695541226/src/synergos-frontend/pages/experience/live/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "experience-wait-slug",
    path: "/experience/wait/:slug(.*)*",
    meta: _91_46_46_46slug_931gJaZeq03tMeta || {},
    component: () => import("/codebuild/output/src1695541226/src/synergos-frontend/pages/experience/wait/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexwE2fHiBAPzMeta || {},
    component: () => import("/codebuild/output/src1695541226/src/synergos-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginkoC0JThGavMeta || {},
    component: () => import("/codebuild/output/src1695541226/src/synergos-frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingnNpuYZqfXEMeta || {},
    component: () => import("/codebuild/output/src1695541226/src/synergos-frontend/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/signup",
    meta: signup4hiaJJgWreMeta || {},
    component: () => import("/codebuild/output/src1695541226/src/synergos-frontend/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: "systemCheck",
    path: "/systemCheck",
    meta: systemCheckrHGiwnufvvMeta || {},
    component: () => import("/codebuild/output/src1695541226/src/synergos-frontend/pages/systemCheck.vue").then(m => m.default || m)
  },
  {
    name: component_45stubWi5ykHMcW8Meta?.name,
    path: "/docs",
    component: component_45stubWi5ykHMcW8
  }
]