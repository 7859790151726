import type { UseFetchOptions } from '#app'

export async function useApiFetch<T>(path: string | (() => string), options: UseFetchOptions<T> = {}) {
  let headers: HeadersInit = {}
  const config = useRuntimeConfig()

  const token = useCookie('XSRF-TOKEN')

  if (token.value) {
    headers['X-XSRF-TOKEN'] = token.value as string
  }

  if (import.meta.server) {
    headers = {
      ...headers,
      ...useRequestHeaders(['referer', 'cookie'])
    }
  }

  try {
    const response = await $fetch<T>(config.public.apiBase + path, {
      credentials: 'include',
      watch: false,
      ...options,
      headers: {
        ...headers,
        ...options?.headers
      }
    })

    return response
  } catch (error) {
    if ((error as any).response) {
      // Assuming error.response contains the error details you can show to the user
      const errorMessage = await (error as any).response._data.error
      console.error('API Fetch Error:', errorMessage)
      throw new Error(errorMessage || 'An unknown error occurred')
    } else {
      console.error('Network Error:', error)
      throw new Error('Network error')
    }
  }
}