<script setup lang="ts">
const links = [
  {
    label: 'Adventures',
    to: '/#adventures'
  },
  {
    label: 'Prices',
    to: '/pricing#pricing'
  }
]
</script>

<template>
  <UHeader class="bg-white shadow-md py-4 px-6 justify-between items-center" :links="links">
    <template #logo>
      <span class="text-2xl font-semibold text-gray-900">SYNERGOS</span>
    </template>

    <nav class="hidden lg:flex space-x-8">
      <a v-for="link in links" :key="link.to" :href="link.to"
        class="text-lg text-gray-700 hover:text-gray-900 transition-colors duration-200">
        {{ link.label }}
      </a>
    </nav>

    <template #right>
      <div class="flex items-center space-x-4">
        <UButton label="Sign in" color="gray" to="/login"
          class="hover:bg-gray-100 px-4 py-2 rounded-full transition-colors duration-200" />
        <UButton label="Sign up" icon="i-heroicons-arrow-right-20-solid" trailing color="black" to="/signup"
          class="hidden lg:flex hover:bg-gray-900 hover:text-white px-4 py-2 rounded-full transition-colors duration-200" />
      </div>
    </template>
  </UHeader>
</template>