import { defineStore } from 'pinia'
import { ref, computed } from 'vue' // Import the necessary dependencies

type User = {
    id: number
    user_type_id: number
    first_name: string
    last_name: string
    email: string
    profile: {
        area_code: string
        company_id: number
        country_id: number
        created_at: string
        deleted_at: string | null
        id: number
        phone_number: string
        updated_at: string
        user_id: number
        email_notifications_enabled: boolean
    }
}

type Token = {
    token: string
}

type Credentials = {
    email: string
    password: string
}

// Set the expiration delay for the token in milliseconds
const expirationDelay: number = 3 * 60 * 60 * 1000 // 3 hours

export const useAuthStore = defineStore('auth', () => {
    const user = ref<User | null>(null)
    const token = ref<Token | null>(null)
    const isLoggedIn = computed(() => !!user.value && user.value.user_type_id === 2)
    
    // Track the user's credits
    const credits = ref(0)

    // Track if the user's credits should be fetched
    const fetchCredits = ref(false)

    /**
     * Fetches the user data from the server.
     */
    async function fetchUser() {

        // Check if the token is expired
        if (isTokenExpired()) {
            logout()
            return
        }

        // Get the token from the local storage
        const token = localStorage.getItem('token')

        if (!token) {
            return
        }
        
        const data = await useApiFetch('/api/user', {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        user.value = data as User
    }

    /**
     * Logs in the user and stores the token in the local storage.
     */
    async function login(credentials: Credentials) {
        // Get the CSRF token and update set-cookie
        await useApiFetch('/sanctum/csrf-cookie')

        const login = await useApiFetch('/api/login', {
            method: 'POST',
            body: JSON.stringify(credentials),
        }) as Token

        // Store the token in the local storage
        if (login.token) {
            // Set the token expiration time
            const expirationTime = Date.now() + expirationDelay
            localStorage.setItem('token', login.token)
            localStorage.setItem('token_expiration', expirationTime.toString())
        }

        // Fetch the user data
        await fetchUser()

        return login
    }

    /**
     * Logs in the user using a social provider and stores the token in the local storage.
     */
    async function loginSocial(provider: string, token: string) {
        // Get the CSRF token and update set-cookie
        await useApiFetch('/sanctum/csrf-cookie')

        const login = await useApiFetch(`/api/auth/${provider}/verify`, {
            method: 'POST',
            body: JSON.stringify({
                provider: provider,
                token: token
            }),
        }) as Token

        // Store the token in the local storage
        if (login.token) {
            // Set the token expiration time
            const expirationTime = Date.now() + expirationDelay
            localStorage.setItem('token', login.token)
            localStorage.setItem('token_expiration', expirationTime.toString())
        }

        // Fetch the user data
        await fetchUser()

        return login
    }

    /**
     * Removes the token and token expiration time from the local storage.
     */
    function logout() {
        localStorage.removeItem('token') // Remove the token
        localStorage.removeItem('token_expiration') // Remove the token expiration time
        user.value = null // Reset the user
    }

    /**
     * Checks if the token is expired.
     */
    function isTokenExpired(): boolean {
        const expirationTime = localStorage.getItem('token_expiration')
        if (!expirationTime) {
            return true
        }
        return Date.now() > parseInt(expirationTime)
    }

    return { user, login, loginSocial, isLoggedIn, fetchUser, token, logout, credits, fetchCredits }
})
