<script setup lang="ts">
const colorMode = useColorMode()

const color = computed(() => colorMode.value === 'dark' ? '#111827' : 'white')

useHead({
  meta: [
    { charset: 'utf-8' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    { key: 'theme-color', name: 'theme-color', content: color }
  ],
  link: [
    { rel: 'icon', href: '/favicon.ico' }
  ],
  htmlAttrs: {
    lang: 'en'
  }
})

useSeoMeta({
  titleTemplate: '%s - Synergos',
  ogSiteName: 'Synergos',
  ogType: 'website',
  ogImage: 'https://synergos-assets.s3.amazonaws.com/images/synergos_logo.png',
  twitterCard: 'summary_large_image',
  twitterSite: '@synergosapp',
  twitterDescription: 'Unforgettable Team Adventures',
  twitterTitle: 'Synergos',
  twitterImage: 'https://synergos-assets.s3.amazonaws.com/images/synergos_logo.png',
})
</script>

<template>
  <div>
    <NuxtLoadingIndicator />

    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <UNotifications />
  </div>
</template>
